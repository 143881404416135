import '../styles/index.scss';
import $ from 'jquery';
import 'bootstrap/js/dist/carousel';

// console.log('webpack starterkit');

$(()=> {
    $(".map-image").on("click",(e)=>{
        let elem = $(e.target);
        elem.attr("src",elem.attr("load-src"));
        elem.toggleClass("show");
    });

    $("#show-more").on("click",(e)=>{
        $(e.target).toggleClass("d-none");
        $("#show-less").toggleClass("d-none");
        $("#more-description").toggleClass("d-none");
    });

    $("#show-less").on("click",(e)=>{
        $(e.target).toggleClass("d-none");
        $("#show-more").toggleClass("d-none");
        $("#more-description").toggleClass("d-none");
    });
});